.projects-container {
    padding-top: 40px;
    .projects-content {
        margin: 5rem 0 3rem 0
    }
    .uday-documentery {
        padding: 2rem 30px;
        border-radius: 10px;
    }
}

