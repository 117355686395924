.project-arch-container {
    display: flex;
    border-radius: 10px;
    position: relative;
    width: 90%;
    margin: 40px auto;
    background-color: #F9F9F9;
    padding: 10px;
    height: 400px;
    .project-info-container {
        flex: 0.5;
      
        z-index: 20;
        
        .project-title {
            font-weight: 700;
            font-size: 1.8rem;
            color: black;
            margin-top: 40px;
            padding:0 20px;
        }
        .subtitle-container {
            display: flex;
            flex-direction: column;
            .project-subtitle {
                color: #222222;
                margin-top: 10px;
                font-size: 1.4rem;
                padding:0 20px;
            }
    
        }
        
        .know-more {
            
            margin:4.5rem 20px;
        }
    }
    .project-image {
        transition-duration: 1s;
        flex: 0.5;
        width: 50%;
        object-fit: cover;
        position: absolute;    
        overflow: hidden;
        border-radius: 10px;
        height: 400px;
        top: 0;
        background-color: red;
        filter: grayscale(0%)  blur(0px) hue-rotate(0deg);
        -webkit-transform: translateZ(0);
    }

    &:hover {
        .project-info-container {
            transition-duration: 1s;
            z-index: 01;
            width: 100%;
            position: absolute;
            .project-title {
                transition-duration: 1s;
               color: white;
               -webkit-transform: translateZ(0);
            }
            .project-subtitle {
                transition-duration: 1s;
                color: white;
                -webkit-transform: translateZ(0);
            }
        }
        .project-image {
            transition-duration: 1s;
            position: absolute;    
            -webkit-transform: translateZ(0);
            object-fit: cover;
            width: 100%;
            height: 400px;
           
            filter: blur(10px) grayscale(80%) hue-rotate(10deg); 
            will-change: auto;
            -webkit-backface-visibility: hidden;
            -webkit-perspective: 1000;
            -webkit-transform: translate3d(0,0,0);
            -webkit-transform: translateZ(0);
            backface-visibility: hidden;
            perspective: 1000;
            transform: translate3d(0,0,0);
            transform: translateZ(0);
            
        }
    }
}

@media (max-width:1133px) {
    .project-arch-container {
        .project-image {
            width: 48%;
        }
    }
}

@media (max-width:551px) {
    .project-arch-container {
        .project-info-container {
            .project-title {
                font-size: 1.3rem;
            }
            .subtitle-container {
                display: flex;
                flex-direction: column;
                .project-subtitle {
                    font-size: 1rem;
                }
            }
        .know-more {
            margin-top: 2rem;
        }
    }
    }
}