.covid-stats-content {
    display: flex;
    flex-direction: column;
    margin: 2rem 50px;
    align-items: center;
    width: 250px;
    user-select: none;
    .stats-value {
        background: -webkit-linear-gradient(#F57B51, #FE0000);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        font-size: 3.5rem;
    }
    .stats-title {
        color: #222;
        font-weight: bold;
        font-size: 1.4rem;
    }
}