.carosel-container {
    .image-holder {
        background-color: #fff;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        scroll-behavior: unset;
        width: 100vw;
        height: 590px;
        scroll-behavior: smooth;
        -ms-overflow-style: none;  
        scrollbar-width: none; 
        &::-webkit-scrollbar {
            display: none;
        }
        .hero-container-one {
            position: relative;
        
            .carosel-container {
                width: 100vw;
                background-repeat: no-repeat;
                background-size: cover;
                
                .carosel-image {
                    width: 100vw;
                    object-fit: cover;
                    height: 500px;
                    
                }
                .carosel-tag {
                    text-align: center;
                    font-size: 1.6rem;
                    padding: 40px 0;
                    font-weight: 700;
                    color: #F3F3F3;
                    background: rgba(0, 0, 0, 0.398)  ;
                    margin-top: -18px;
                    height: 105px;
                    user-select: none;
                }
                
            }
        }   
}
}

@media (max-width:1011px) {
    .carosel-container {
        .image-holder {
            .hero-container-one  {
                .carosel-container {
                    .carosel-image {
                        height: 300px;
                    }
                    .carosel-tag {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
    
}