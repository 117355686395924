* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Karla";
  scroll-behavior: smooth;
}

.button {
  background-color: #F57B51;
  border:none;
  border-radius: 20px;
  padding: 10px 40px;
  cursor: pointer;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.button:hover {
  transition-duration: 1s;
  background-color: #fa5e2a;
}

.button:focus {
  outline: none;
}

.loading-screen {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}
.splash-logo {
  width: 150px;
  animation: splash-logo 1s linear;
  animation-fill-mode: forwards;
}

.progress {
  margin-top: 2rem;
}

@keyframes splash-logo {
  from{
    filter: blur(15px);
  }
  to {
    filter: blur(0px);
  }
}