.contact-us-container {
    .contact-us-content {
        display: flex;
        justify-content: space-evenly;
        background-image: url("../../assets/contact-us-background.png");
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 2rem;
        .form-heading {
            font-size: 2rem;
            font-weight: bold;
            padding-top: 30px;
        }

        .contact-us-form-container {
            padding: 0 30px;
           .form-sub-heading {
               font-size: 1.5rem;
               padding-top: 10px;
           }

           .contact-us-form {
               display: grid;
               grid-template-columns: repeat(2, 45%);
              
               grid-gap: 15px;
               padding-top: 20px;

               .input-field {
                   padding: 010px 10px;
                   background-color: rgba(255, 255, 255, 0.8);
                   border-radius: 5px;
                   border: 0.5px solid #999999;
                   &::placeholder {
                       color: #ef680c;
                       font-weight: bold;
                   }
               }

               .address-field, .message-field {
                   grid-column: 1/3;
               }

               .message-field {
                   height: 100px;
                   resize: none;
                   padding: 5px 10px;
               }

               .contact-us-button {
                grid-column: 1/3;
                margin: 0 25%;
               }
           }

           
        }

        .head-office-container {
            padding: 0 30px;
            .head-office-details {
                font-size: 1.2rem;
                padding-top: 10px;
            }

            .social-links-container {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
            }
        }

        
    }

    .bottom-section {
        display: flex;
        justify-content: center;
        background-color: #444444;
        padding: 1rem;
        color: white;

    } 
}

@media(max-width:837px) {
    .contact-us-container {
        .contact-us-content {
            flex-direction: column;

            .social-links-container {
                width: 300px;
            }

            .head-office-container {
                .form-heading {
                    margin-top: 2rem;
                }
            }
          
        }
    }
}

@media(max-width:578px) {
    .contact-us-container {
        .contact-us-form-container {
            .contact-us-form {
               
              

                .address-field, .message-field, .input-field {
                    grid-column: 1/3;
                }
            }
        }
    }
}