.covid-package-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 359px;
    margin: 30px 50px;
    user-select: none;
    .package-icon  {
        width: 100px;

    }
    .package-title {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
        margin-top: 15px;
    }
    .package-subtitle {
        text-align: center;
        font-size: 1.1rem;
        margin-top: 10px;
    }
}