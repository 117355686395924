.covid-19-container {
    padding-top: 40px;
    .section-one {
        margin-top: 3rem;
    }
    .section-two {
        margin-top: 1rem;
        margin-bottom: 4rem;
    }
    .section-one, .section-two {
        padding: 0 50px;
        font-weight: 400;
        color: black;
        font-size: 1.2rem;
    }

    .covid-giveaway-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 30px;
    }
    .covid-stats-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2rem;
    }
    .media-container {
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 3rem;
        .covid-banner {
            width: 100%;
            margin: 0 0 5rem 0;
            border-radius: 10px;
        }
        .media-content-preview {
            display: flex;
            justify-content: center;
        }
        .media-list {
            background-color: #fff;
            display: flex;
            overflow-x: scroll;
            border-radius: 20px;
            margin-top: 30px;
            scroll-behavior: unset;
            .news-clips {
                width: 400px;
                height: 150px;
                margin: 30px 20px;
                scroll-behavior: unset;
                border-radius: 5px;
                background: linear-gradient(145deg, #e5e5e5, #c1c1c1);
                box-shadow:  20px 20px 33px #bcbcbc,
             -20px -20px 33px #f0f0f0;
                &:hover{
                    filter: blur(1px);
                    cursor: pointer;
                    box-shadow:  20px 20px 33px #919090,
             -20px -20px 33px #eeeded;
                    
                }
            }

            
        }

        .media-list::-webkit-scrollbar {
            display: none;
          }
          
          /* Hide scrollbar for IE, Edge and Firefox */
          .media-list {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }
    }

    .know-more {
        cursor: pointer;
        color: #F57B51;
        font-size: 1.5rem;
        margin-top: 2rem;
        text-align: end;
        padding: 0 30px;
        font-weight: bold;
    }
}

@media (max-width:757px) {
    .covid-19-container {
        .section-one, .section-two {
            padding: 0 30px;
        }
    }
}