.collage-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    padding: 0 30px;
  
    .collage_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
     }
    .left-half {
        display: grid;
        grid-template-columns: repeat(3,0.35fr);
        grid-template-rows: repeat(3,200px);
        grid-gap: 10px;
        margin: 0 5px;
       
        .lh_00 {
            grid-column: 1/3;
            grid-row: 1/3;
        }
        .lh_13  {
            grid-column: 2/4;
        }
    }

    .right-half {
        display: grid;
        grid-template-columns: repeat(3,0.35fr);
        grid-template-rows: repeat(3,200px);
        grid-gap: 10px;
        margin: 0 5px;
       
    }
    .rh_00 {
        grid-column: 1/3;
    }
    .rh_11 {
        grid-column: 2/4;
    }
    .rh_10 {
        grid-row: 2/4;
    }
}

@media (max-width:1001px) {
    .collage-container {
        flex-direction: column;
        padding: 0 20px;
        .left-half {
            margin-bottom: 10px;
        }
    }
}