.section-title-container {
    background-color: #F57B51;
    border-radius: 0px 64px 64px 0px;
    display: inline-block;
    padding: 0 20px;
    min-width: 300px;
    max-width: 90%;
    .title {
        color: white;
        font-weight: 700;
        padding: 18px 30px;
        font-size: 1.4rem;
        text-align: left;

    }
}