.mobile-navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 0.5rem;
    .logo {
        width: 4.5rem;
        height: 4.5rem;
        grid-column: 1/2;
        object-fit: contain;
    }
    .anniversary {
        width: 4.5rem;
        height: 4.5rem;
        grid-column: 2/3;
        object-fit: contain;
    }
    .tagline {
        font-family: 'Pacifico';
        color: #DA251B;
        font-size: 1rem;
        grid-row: 2/3;
        grid-column: 1/3;
        text-align: start;
    }

    .side-bar-container {
        transition-duration: 5s;
        position:absolute;
        
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
       
        justify-content: flex-end;

        will-change: auto;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        -webkit-transform: translate3d(0,0,0);
        -webkit-transform: translateZ(0);
        backface-visibility: hidden;
        perspective: 1000;
        transform: translate3d(0,0,0);
        transform: translateZ(0);
        z-index: 10;

        .grey-area {
            background-color: #DA251B;
            width: 55%;
            animation: sidebarbg 0.3s linear;
            animation-fill-mode: forwards;
            will-change: auto;
            -webkit-transform: translateZ(0);
        }
        .nav-container {
            background-color: white;
            z-index: 20;
            color: #444;
            width: 45%;
            display: flex;
            flex-direction: column;
            padding: 3rem 20px;
            animation: nav-items 0.3s linear ;
            animation-fill-mode: forwards;
            will-change: auto;
            -webkit-transform: translateZ(0);
            
            .nav-item {
                font-weight: bold;
                margin-top: 20px;
                white-space: nowrap;
                animation: nav-item-anim 0.3s linear ;
                will-change: auto;
                -webkit-transform: translateZ(0);
                text-decoration: none;
                color: black;
                
                &:hover {
                    transition-duration: 0.8s;
                    border-bottom: 2px solid black; 
                }
                &:visited &:active {
                    text-decoration: none;
                    border: 2px solid #FCC91B;
                }
                
            }
        }
    }
    @keyframes sidebarbg {
        from { background-color: rgba(51, 51, 51, 0); width: 100%;}
        to { background-color: rgba(136, 136, 136, 0.226);width: 60%;}
      }
    @keyframes nav-item-anim {
        from { color: white;}
        to { color: #444;}
    }
    @keyframes nav-items {
        from { width: 0%;}
        to { width: 45%;}
    }
}