.project-list-container {
    .sectors-container {
        margin: 2rem auto 10px auto;
        padding: 4rem 30px;
        width: 97%;
        display: flex;
        flex-direction: column;
        height: 600px;
        background-color: rgba(0, 0, 0, 0.466);
        background-size: cover;
        border-radius: 10px;
        background-blend-mode: darken;
        color: #FFFFFF;
        .project-sector-name {
            font-weight: bold;
            font-size: 2.5rem;
            
        }
        .project-sector-tagline {
            font-size: 1.5rem;
            margin-top: 20px;
        }
        .project-name-lists {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            .project-name-link {
                margin-right: 20px;
                margin-top: 20px;
                color: white;
                text-decoration: none;
                border: 1px solid white;
                padding: 10px 20px;
                border-radius: 20px;
                
                &:hover{
                    transition-duration: 1s;
                    background-color: #ffffff46;
                }
            }
        }
    }
    .projects-container {
        .sector-project-container {
            margin-bottom: 4rem;
            .project-detail-container {
                padding: 0 30px;
                .project-paragraph {
                    font-size: 1.2rem;
                    margin-top: 20px;
                }
                .bullet-container{
                    margin-top: 20px;
                    .bullet-title {
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                    .list-container {
                        list-style-image: url('../../assets/right-arrow.png');
                        
                        .list-item {
                            margin: 10px 30px;
                            font-size: 1.2rem;
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
    }
}