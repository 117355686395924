.quotes-container {
    margin: 10px 50px 0 50px;
    .quote-icon {
        width: 50px;
    }
    .quotes-title-container {
        display: flex;
        align-items: center;
        .quote-title {
            margin: 0 auto;
            color: #222222;
            font-weight: 700;
            font-size: 1.4rem;
        }
    }

    .quote-content {
        font-style: italic;
        font-size: 1.2rem;
        color: black;
        margin: 0 20px;
    }
    .bottom-quote {
        margin-left: 100%;
        transform: translateX(-100%);
    }
}


@media (max-width:757px) {
    .quotes-container {
        margin: 10px 40px 0 40px;
        .quote-icon {
            width: 30px;
        }
    }
}