.navigaion-container {
    display: flex;
    flex-direction: column;
    
    
    .logo-container {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        .logo-menu-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo-tagline-container {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 10px;
                .logo {
                    width: 60px;
                    height: 60px;
                }
                .tagline {
                    font-family: 'Pacifico';
                    color: #DA251B;
                    font-size: 0.9rem;
                    margin-left: 20px;
                    user-select: none;
                }
            }

            .nav-container {
                display: flex;
                justify-content: center;
                align-items: center;
              
                .nav-item {
                    margin: 15px 20px;
                    font-weight: regular;
                    user-select: none;
                    cursor: pointer;
                    text-decoration: none;
                    color: black;
                    &:hover {
                        transition-duration: 0.8s;
                        border-bottom: 2px solid black; 
                    }
                    &:visited &:active {
                        text-decoration: none;
                        border: 2px solid #FCC91B;
                    }
                }
                .donate-now {
                    background-color: rgba(0, 0, 0, 0.788);
                    color: white;
                    padding: 6px 10px;
                    border-radius: 10px;
                    border: 2px solid black;
                    &:hover{
                        transition-duration: 1s;
                        background-color: rgb(0, 0, 0);
                        border: 2px solid black;
                    }
                }
        
            }
        }
        

        .anniversary-container {
            .anniversary {
                width: 90px;
                height: 90px;
                object-fit: contain;
            }
        }
    }

    
}

@media (max-width:1106px) {
    .navigaion-container {
       .nav-container {
           .nav-item {
               margin: 25px 15px;
           }
       }
    }
}

