.governance-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .banner {
        width: 100%;
        height: 300px;
        margin-top: 30px;
        margin-bottom: -40px;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 3rem 4rem;
       
        .set-container {
            display: flex;
            flex-direction: row;
            margin-top: 70px;

            .heading {
                flex: 0.3;
                color: #545454;
                font-size: 1.2rem;
                font-weight: bold;
            }

            .list-date-container {
                flex: 0.7;
                display: flex;
                flex-direction: column;
                width: 100%;

                .bar {
                    width: 100%;
                    height: 2px;
                    background-color: #ccc;
                }
                .sub-heading {
                    color: #545454;
                    font-size: 1rem;
                    font-weight: medium;
                    margin-top: 5px;
                }

                .doc-list-container {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-left: -70px;
                    margin-right: -70px;
                    margin-top: 20px;

                    .doc-detail-container {
                        display: flex;
                        flex-direction: column;
                        margin: 20px 70px;

                        .date {
                            color: #444;
                            font-size: 1rem;
                            font-weight: bold;
                        }

                        .file-name {
                            color: red;
                            font-size: 1.2rem;
                            font-weight: bold;
                            cursor: pointer;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:900px) {
    .governance-container {
    
        .content-container {
            padding: 1rem 1rem;
        }
    }
         
}

@media(max-width:700px) {
    .governance-container {    
        .content-container {
           
            .set-container {
                flex-direction: column;
    
                .heading {
                    margin-bottom: 10px;
                }
    
                .list-date-container {
                    flex: 0.7;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
    
                    .bar {
                        width: 100%;
                        height: 2px;
                        background-color: #ccc;
                    }
                    .sub-heading {
                        color: #545454;
                        font-size: 1rem;
                        font-weight: medium;
                        margin-top: 5px;
                    }
    
                    .doc-list-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-left: -70px;
                        margin-right: -70px;
                        margin-top: 20px;
    
                        .doc-detail-container {
                            display: flex;
                            flex-direction: column;
                            margin: 20px 70px;
    
                            .date {
                                color: #444;
                                font-size: 1rem;
                                font-weight: bold;
                            }
    
                            .file-name {
                                color: red;
                                font-size: 1.2rem;
                                font-weight: bold;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    } 
}