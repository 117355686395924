.carousel-container {
   
    .image-holder {
        background-color: #fff;
        display: flex;
       
        border-radius: 20px;
        overflow-x: scroll;
        margin-top: 30px;
        scroll-behavior: unset;
        width: 97vw;
        margin: 2rem auto 10px auto;
        scroll-behavior: smooth;
        -ms-overflow-style: none;  
        scrollbar-width: none; 
        &::-webkit-scrollbar {
            display: none;
        }
        .carosel-image-container {
           margin-right: 10px;
            
            .carosel-image {
                height: 700px;
                width: 97vw;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        
    }
}