.partners-container {
    padding-top: 40px;
    .icon-container {
        display: flex;
        padding: 2rem 4rem;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .partner-logo{
            width: 70px;
            height: 70px;
            object-fit: contain;
            margin: 30px;          
           
        }
    }
}

@media (max-width:1371px) {
    .partners-container {
        
    }
}

@media (max-width:555px) {
    .partners-container {
        
    }
}

















// .partners-container {
//     padding-top: 40px;
//     .icon-container {
//         display: grid;
//         padding: 2rem 4rem;
//         grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
//         grid-gap: 30px;
//         justify-items: center;
//         .partner-logo{
//             width: 70px;
//             height: 70px;
//             object-fit: contain;
            
           
//         }
//     }
// }

// @media (max-width:1371px) {
//     .partners-container {
//         .icon-container {
//             grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//             .partner-logo{
//                 width: 120px;
//                 height: 120px;
//             }
//         }
//     }
// }

// @media (max-width:555px) {
//     .partners-container {
//         .icon-container {
//             grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
//             .partner-logo{
//                 width: 150px;
//                 height: 150px;
//             }
//         }
//     }
// }