.about-container {
    padding-top: 40px;
    
    .section-one {
        margin-top: 3rem;
    }
    .section-two {
        margin-top: 1rem;
        margin-bottom: 4rem;
    }
    .section-one, .section-two {
        padding: 0 50px;
        font-weight: 400;
        color: black;
        font-size: 1.2rem;
    }

    .image-container {
        padding: 0 50px;
        position: relative;
        height: 450px;
        margin-top: 2rem;
        .image-content {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
           
            
        }
        .image-caption {
            background: rgba(0, 0, 0, 0.49);
            border-radius: 0px 0px 10px 10px;
            text-align: center;
            color: white;
            padding: 20px 0;
            position: absolute;
            bottom: 0;
            font-size: 1.2rem;
            
        }
        
    }
}

@media (max-width:757px) {
    .about-container {
        .section-one, .section-two {
            padding: 0 30px;
        }
        .image-container {
            padding: 0 30px;
            height: 250px;
        }
    }
}