.hero-container {
    // width: 100%;
    // height: 590px;
    // overflow: hidden;
    // display: flex;
    position: relative;
   
    .background-image-container {
        position: absolute;
        z-index: -20;
        top: 0;
        left: 0;
        width: 100%;
        
        .background-image{
            width: 100%;
            
            overflow: hidden;
            height: 590px;
            will-change: true;
            -webkit-backface-visibility: hidden;
            -webkit-perspective: 1000;
            -webkit-transform: translate3d(0,0,0);
            -webkit-transform: translateZ(0);
            backface-visibility: hidden;
            perspective: 1000;
            transform: translate3d(0,0,0);
            transform: translateZ(0);
            object-fit: cover;
            filter: grayscale(40%)
            // backdrop-filter: blur(80px);
           
        }
    }

    .carosel-container {
      
        overflow: hidden;
        .carosel-image {
            width: 100%;
            object-fit: cover;
            height: 500px;
            
        }
        .carosel-tag {
            text-align: center;
            font-size: 1.6rem;
            padding: 40px 0;
            font-weight: 700;
            color: #F3F3F3;
            background: rgba(0, 0, 0, 0.39);
            margin-top: -18px;
            height: 105px;
        }
    }
}

@media (max-width:1011px) {
    .hero-container {
        .background-image-container {
            .background-image{
                height: 390px;
               
               
            }
        }
        .carosel-container {
            .carosel-image {
                height: 300px;
            }
            .carosel-tag {
                font-size: 0.9rem;
               
            }
        }
    }
}


