.footer-container {
    background-color: #F57B51;
    
    .top-section {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 30px;
        justify-items: center;
        padding: 4rem 30px;
        .footer-subcontainer {
           width: 300px;
            .footer-title {
                font-weight: 700;
                color: #444444;
                font-size: 1.2rem;
            }

            .footer-entity {
                display: flex;
                margin: 15px 0;
                align-items: center;
                cursor: pointer;
            }
            .footer-text {
                margin: 0 10px;
                font-weight: bold;
                color: white;
                text-decoration: none;
                display: block;
            }
            .footer-nav-text {
                margin: 15px 0;
                font-weight: bold;
                color: white;
                cursor: pointer;
                border: 1px solid #F57B51;
                padding: 2px 0;
                text-decoration: none;
                &:hover{
                    transition-duration: 0.8s;
                    color: rgb(248, 248, 187);
                }
            }
        }
    }
    .bottom-section {
        display: flex;
        justify-content: center;
        background-color: #444444;
        padding: 1rem;
        color: white;

    }
}